import React from "react";
import { Global } from "@emotion/core";
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import SEO from "../components/seo";
import LightSwitch from "../components/LightSwitch";
import { formatPostDate } from "../utils/helpers";

import "./post.css";

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        author
        date
      }
      body
    }
  }
`;

const PostTemplate = ({ data: { mdx: post } }) => {
  return (
    <>
      <SEO title={post.frontmatter.title} />
      <Global
        styles={(theme) => ({
          body: {
            color: theme.colors.bodyColor,
            backgroundColor: theme.colors.bodyBg,
            transition: `background-color 500ms`,
          },
        })}
      />
      <LightSwitch />
      <main className="post-template">
        <article>
          <header>
            <h1>{post.frontmatter.title}</h1>
            <div>
              <p>{post.frontmatter.author}</p>
              <p>{formatPostDate(post.frontmatter.date)}</p>
            </div>
          </header>
          <div className="resetStyles">
            <MDXRenderer>{post.body}</MDXRenderer>
          </div>
          <footer>
            <Link to="/">&larr; Go back home</Link>
          </footer>
        </article>
      </main>
    </>
  );
};

export default PostTemplate;
